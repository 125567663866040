.carousel-container {
    width: 100%;
    margin: 0 auto;

    .slick-track {
        display: flex;

        .slick-slide {
            text-align: center;
            margin: 5px;
            width: 100% !important;
            height: 100% !important;
        }

        .slick-slide img {
            display: inline-block;
            max-width: 100%;
        }
    }



    .slick-prev,
    .slick-next {
        width: 40px;
        height: 40px;
        background-color: rgba(44, 41, 41, 1);
        border-radius: 50%;
        z-index: 1;

        &:hover {
            background-color: rgba(0, 0, 0, 0.5) !important;
        }

        &:focus {
            background-color: #2C2929 !important;
        }
    }

    .slick-next {
        right: 32%;
    }

    .slick-prev {
        left: 32%;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 20px;
    }

    .slick-next:before {
        content: url('../../../content/images/icons/navigate-right.png');
        color: white;
    }

    .slick-prev:before {
        content: url('../../../content/images/icons/navigate-left.png');
        color: white;
    }

    .slick-dots li {
        width: 90px;
        height: 3px;
    }

    .slick-dots li button {
        width: 90px;
        height: 3px;
    }

    .slick-dots li button:before {
        font-size: 12px;
        background-color: rgba(44, 41, 41, 1);
        content: '';
        width: 90px;
        height: 3px;
        background-color: rgba(44, 41, 41, 1);
    }

    .slick-dots li.slick-active button:before {
        content: '';
        width: 90px;
        height: 3px;
        background-color: rgba(44, 41, 41, 1);
    }
}

@media only screen and (max-width: 767px) {
    .carousel-container {

        .slick-prev,
        .slick-next {
            display: none !important;
        }
    }
}