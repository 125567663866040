.privacy-policy {
    padding: 2rem;

    h3::after {
        content: "";
        display: block;
        width: 50px;
        height: 5px;
        background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
        margin: 10px auto 0;
    }

    .content {
        margin-top: 4rem;

        .policy-contanier {
            margin-top: 4rem;

            .policy-item {
                display: flex;

                img {
                    width: 72px;
                    height: 72px;
                    margin-right: 2rem;
                }

                .policy-content {
                    width: 100%;

                    h5 {
                        font-family: "Zen Kaku Gothic New", sans-serif;
                        font-size: 25px;
                        font-weight: 700;
                        line-height: 42px;
                        letter-spacing: 0.06em;
                        text-align: left;
                        margin: 10px auto;
                    }
                }
            }

        }
    }
}

@media only screen and (max-width: 767px) {
    .privacy-policy {
        padding: 10px;

        .content {
            .policy-contanier {
                .policy-item {
                    flex-direction: column;
                    align-items: center;

                    img {
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }
            }
        }

        .mission-item {
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 10px;
            }
        }
    }
}