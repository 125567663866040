.flow {

    .flow-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .flow-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 20px;
        width: 70%;
        text-align: left;
        min-height: 19rem;

        img {
            width: 10rem;
            height: auto;
            margin-right: 3rem;
        }

        .flow-text {

            h4,
            h5,
            p {
                line-height: 42px;
                letter-spacing: 0.08em;
                margin: 10px 0;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                color: #F5894F;
                font-size: 20px;
            }

            h5 {
                font-size: 31px;
                font-family: 'Zen Kaku Gothic New', sans-serif;
            }

            p {
                font-size: 16px;
                margin: 10px 0;
                white-space: pre-line;
                /* Preserves line breaks */
            }

            .btn-container {
                display: flex;
                column-gap: 20px;

                button {
                    width: 12rem;
                    padding: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .flow {
        .flow-item {
            flex-direction: column;
            align-items: center;
            width: 90%;

            img {
                margin-right: 0;
            }

            .flow-text {
                width: 100%;
            }
        }
    }
}