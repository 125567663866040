.contact-us {
    text-align: center;
    padding: 3rem;
    background-color: rgba(245, 137, 79, 0.07);

    p {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 25px;
        font-weight: 700;
        line-height: 61px;
        letter-spacing: 0.18em;
        text-align: center;
    }

    .contact-button {
        margin-bottom: 3rem;
    }
}