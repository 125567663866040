.application-container {
    margin-top: 3rem;

    .intro-txt {
        text-align: center;
    }

    h3::after {
        content: "";
        display: block;
        width: 50px;
        height: 5px;
        background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
        margin: 10px auto 0;
        position: absolute;
        left: calc(50% - 50px);
    }

    .application-form-container {
        padding: 20px;
        margin: 4rem auto;
        width: 80%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px !important;

        h3::after {
            content: "";
            display: block;
            width: 50px;
            height: 5px;
            background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
            margin: 10px auto 0;
            position: absolute;
            left: calc(50% - 50px);
        }

        .application-form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-top: 5rem;
            padding: 0 3rem;

            .application-btn-container {
                display: flex;
                justify-content: center;
                width: 100%;
                column-gap: 2rem;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 3rem;
                }
            }

            .dual-input {
                .dual-input-label {
                    font-family: 'Zen Kaku Gothic New', sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.008em;
                    display: block;
                    margin-bottom: 1rem;

                    .required {
                        color: red;
                    }
                }

                .custom-input {
                    display: flex;
                    align-items: center;

                    label {
                        width: 40%;
                        margin-bottom: 0;
                        font-size: 18px;
                        font-size: medium;

                        .required {
                            display: none;
                        }
                    }

                    .custom-file-input {
                        label {
                            width: 100% !important;
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }

            .file-notes {
                margin-top: -15px;
                margin-bottom: 2rem;
                font-size: 14px;
                color: #333;
                text-align: left;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .application-form {
        padding: 0 1rem !important;

        .application-btn-container {
            flex-direction: column;
            align-items: center;

            button {
                margin-bottom: 2rem;
            }
        }
    }

    .dual-input {

        .custom-input {
            flex-direction: column;
            align-items: flex-start !important;

            label {
                margin-bottom: 1rem !important;
            }
        }
    }
}