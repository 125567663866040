.supported-formats {
    text-align: center;
    margin: 20px;

    h3::after {
        content: "";
        display: block;
        width: 50px;
        height: 5px;
        background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
        margin: 10px auto 0;
        position: absolute;
        left: calc(50% - 50px);
    }

    p {
        text-align: center;
    }

    .formats-icons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img {
            width: 150px;
            height: auto;
        }
    }
}