.pricing-table {
    display: flex;
    // flex-wrap: wrap;
    background: rgba(245, 137, 79, 0.07);
    padding: 3rem;
    align-items: center;

    .pricing-desc {
        width: 45%;

        h2,
        h3 {
            text-align: left;
        }

        h3::after {
            content: "";
            display: block;
            width: 50px;
            height: 5px;
            background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
            margin: 10px auto 0;
            position: absolute;
        }

        p {
            &.hint {
                font-size: 14px;
                line-height: 36px;
            }
        }
    }

    .pricing-chart {
        border-collapse: separate;
        flex-grow: 1;
        border-radius: 30px;
        border-spacing: 0;

        th,
        td {
            border: 1px solid #ddd;
            padding: 1.4rem;
            text-align: center;

            font-family: 'Zen Kaku Gothic New', sans-serif;
            font-size: 19px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.08em;
        }

        th {
            background-color: #F5894F;
            color: white;
            font-weight: bold;
        }

        td {
            background-color: #FFFFFF;
        }

        tr:nth-child(even) td {
            background-color: #FBFBFB;
        }

        thead th:first-child {
            border-top-left-radius: 15px;
        }

        thead th:last-child {
            border-top-right-radius: 15px;
        }

        tbody tr:last-child td:first-child {
            border-bottom-left-radius: 15px;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: 15px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .pricing-table {
        padding: 1rem;
        flex-wrap: wrap;

        .pricing-desc {
            width: 100%;
        }

        .pricing-chart {

            th,
            td {
                padding: 1rem;
                font-size: 12px;
            }
        }
    }
}