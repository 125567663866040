.requirements {
    text-align: center;
    padding: 3rem;
    background-color: rgba(245, 137, 79, 0.07);

    h3::after {
        content: "";
        display: block;
        width: 50px;
        height: 5px;
        background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
        margin: 10px auto 0;
        position: absolute;
        left: calc(50% - 50px);
    }

    p {
        text-align: center;
    }

    .req-section {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 3rem;

        .req {
            width: 30%;
            img {
                width: 200px;
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .requirements {
        padding: 2rem;

        .req-section {
            display: flex;
            justify-content: center;
            align-items: center;
            // flex-wrap: wrap;
            flex-direction: column;
    
            .req {
                width: 100%;
            }
        }
    }
}