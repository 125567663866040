.mission {
    background: rgba(245, 137, 79, 0.07);
    text-align: center;
    padding: 3rem;

    h3 {
        margin-bottom: 3rem;
    }

    .mission-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2rem;

        img {
            width: auto;
            height: 70px;
            margin-right: 20px;
        }

        .mission-content {
            text-align: left;

            h5 {
                font-family: 'Zen Kaku Gothic New', sans-serif;
                font-size: 25px;
                font-weight: 700;
                line-height: 42px;
                letter-spacing: 0.06em;
                text-align: left;
                margin-top: 0;
            }

            p {
                font-family: 'Zen Kaku Gothic New', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 42px;
                letter-spacing: 0.08em;
                text-align: left;

            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .mission {
        padding: 10px;

        .mission-item {
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 10px;
            }
        }
    }
}