.footer {
    background-color: #F5894F;
    color: #fff;
    font-family: 'Zen Kaku Gothic New', sans-serif;

    .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .footer-menu {
            display: flex;
            height: 150px;
            align-items: center;
            width: 70%;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 700;
            line-height: 23px;
            letter-spacing: 1px;
            text-align: left;


            .footer-logo {
                cursor: pointer;

                img {
                    height: 60px;
                }
            }
        }



        .footer-nav-content {
            display: flex;
            justify-content: space-between;
            width: 40%;

            ul {
                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 10px;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
            }
        }

        .footer-contact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: #FCD4BA;
            padding: 5px 0;

            p {
                margin: 0;
                color: #F5894F;
                font-size: 25px;
                font-weight: 700;
                line-height: 61px;
                letter-spacing: 0.18em;
                text-align: center;


                .phone-icon {
                    display: inline-block;
                    margin-right: 5px;
                    vertical-align: middle;
                    background-size: cover;
                    padding-bottom: 2px;
                }
            }

            .contact-button {
                background-color: #ffffff;
                color: #F5894F;
                border: 2px solid #F5894F;
                padding: 10px 20px;
                margin-left: 6rem;
                cursor: pointer;
                border-radius: 20px;
                font-family: "Noto Sans JP", sans-serif;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
                text-align: center;
                width: 18rem;

                &:hover {
                    background-color: #ff9974;
                    color: #ffffff;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .footer {
        .footer-content {
            .footer-menu {
                width: 100%;
                flex-direction: column;
                padding: 20px;
                height: 12rem;

                .footer-nav-content {
                    width: 80%;
                }
            }

            .footer-contact {
                flex-direction: column;

                .contact-button {
                    margin-left: unset !important;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}