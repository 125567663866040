.services-tabs {
    text-align: center;
    padding: 50px 20px;
    background-size: cover;
    color: #333;
    margin-top: 5rem;

    .services-tabs-content {
        margin-top: 13%;

        h4 {
            font-family: 'Zen Kaku Gothic New', sans-serif;
            font-size: 35px;
            font-weight: 700;
            line-height: 42px;
            letter-spacing: 0.22em;
            text-align: center;
            margin-bottom: 20px;
        }

        h6 {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.22em;
            text-align: center;
            margin-bottom: 20px;
        }

        .tabs {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            background-color: #ffffff;
            border-radius: 50px;
            height: 7rem;

            .MuiTabs-scroller {
                display: flex;
                justify-content: center;

                .MuiTabs-flexContainer {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }
            }

            .MuiTab-root {
                flex-grow: 1;
                max-width: unset;
                flex-basis: 0;
                display: flex;
                justify-content: center;
                height: 100%;
            }

            button {
                font-family: 'Zen Kaku Gothic New', sans-serif;
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.20em;
                text-align: center;
                color: #2C2929;
                border: 1px solid rgba(217, 217, 217, 0.5);
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 6px;
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    content: url('../../../content/images/icons/down-orrange.png');
                    color: #F5894F;
                    ;
                    // border-top: 0.3em solid;
                    // border-right: 0.3em solid transparent;
                    // border-bottom: 0;
                    // border-left: 0.3em solid transparent;
                    // transform: translateX(-50%) rotate(90deg);
                }

                &.Mui-selected {
                    background-color: #F5894F;
                    color: #ffffff;

                    &::after {
                        content: url('../../../content/images/icons/down.png');
                    }
                }
            }

            .MuiTabs-indicator {
                background-color: unset;
            }
        }

        .tab-content {
            .tab-content-inner {
                display: flex;
                flex-direction: column;
                align-items: center;

                .tab-item-images {
                    text-align: center;

                    img {
                        width: 20rem;
                    }
                }

                .tab-item {
                    margin: 2.5rem 0;
                    text-align: left;
                    width: 80%;

                    .tab-item-content {
                        display: flex;
                        justify-content: space-between;

                        .tab-item-content-img {
                            width: 40%;
                        }

                        .tab-item-content-txt {
                            width: 60%;
                            padding-top: 1rem;

                            .content-header {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 72px;
                                    height: 72px;
                                    margin-right: 4rem;
                                }

                                h4 {
                                    font-family: 'Zen Kaku Gothic New', sans-serif;
                                    font-size: 31px;
                                    font-weight: 700;
                                    line-height: 42px;
                                    letter-spacing: 0.06em;
                                    text-align: left;
                                    margin: 0;
                                }
                            }

                            p {
                                font-family: 'Zen Kaku Gothic New', sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 42px;
                                letter-spacing: 0.08em;
                                text-align: left;
                                margin-left: 8.5rem;
                            }
                        }

                    }
                }
            }
        }
    }


}

@media only screen and (max-width: 1200px) {
    .services-tabs {
        .services-tabs-content {
            margin-top: 5rem;

            .tabs {
                .MuiTab-root {
                    flex-grow: unset;
                    max-width: unset;
                    flex-basis: unset;
                    width: 15rem;
                    height: 100%;
                }

                button {
                    font-size: medium;
                }

                .MuiTabs-flexContainer {
                    justify-content: flex-start !important;
                }

                .MuiTabs-scrollButtons {
                    display: flex;
                }

                .Mui-disabled {
                    display: none;
                }
            }

            .tab-content {
                .tab-content-inner {
                    .tab-item {
                        .tab-item-content {
                            flex-direction: column;
                            align-items: center;

                            .tab-item-content-img {
                                display: flex;
                                justify-content: center;
                                width: 100%;
                            }

                            .tab-item-content-txt {
                                width: 90%;

                                .content-header {
                                    flex-direction: column;
                                    gap: 20px;

                                    img {
                                        margin-right: 0;
                                    }
                                }

                                h4 {
                                    text-align: center;
                                }

                                p {
                                    margin: 0;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .tab-item-images {
                        img {
                            width: 20rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .services-tabs {
        .services-tabs-content {
            margin-top: 50% !important;

            .tab-content {
                .tab-content-inner {

                    .tab-item-images {
                        img {
                            width: 10rem;
                        }
                    }
                }
            }
        }
    }
}