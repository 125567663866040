.kobake-header {
    box-shadow: none !important;
    background-color: #FDFCFA !important;

    .header-content {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        padding-bottom: 0;

        .logo {
            width: 100%;
            cursor: pointer;
        }

        .menu-items {
            width: 100%;
            display: flex;
            justify-content: center;

            .menu-item {
                width: 10rem;
                display: flex;
                justify-content: center;
                padding: 10px;

                &::after {
                    content: "";
                    position: relative;
                    left: 8%;
                    height: 85%;
                    border-right: 1px solid rgba(217, 217, 217, 0.5);
                }

                &:first-child {
                    &::before {
                        content: "";
                        position: relative;
                        height: 85%;
                        border-left: 1px solid rgba(217, 217, 217, 0.5);
                    }
                }

                a {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    span {
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 30px;
                    }
                }

                &.active {
                    background-color: rgba(252, 212, 186, 0.3);
                }
            }
        }
    }
}

.toolbar-spacing {
    height: 12rem;
}

@media only screen and (max-width: 767px) {
    .kobake-header {
        .header-content {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0;

            .logo {
                width: auto;
            }

            button {
                width: 2rem;
                height: 2rem;

                svg {
                    path {
                        fill: #f5884f;
                    }
                }
            }

            .menu-items {
                display: none;
            }
        }
    }

    .MuiDrawer-modal {
        .MuiDrawer-paperAnchorLeft {
            width: 15rem !important;

            .MuiListItemText-primary {
                font-weight: 600 !important;
            }

            .MuiListItem-root {
                &.active {
                    background-color: rgba(252, 212, 186, 0.3);
                }
            }
        }
    }

    .toolbar-spacing {
        height: 128px;
    }
}