.company-overview {
    text-align: center;
    margin-top: 5rem;

    table {
        width: 100%;
        border-collapse: collapse;
        display: flex;
        justify-content: center;
        margin: 5rem auto;

        tbody {
            // padding: 3rem;
            width: 90%;
            box-shadow: 0px 11px 17px 0px rgba(0, 0, 0, 0.06);

            tr {
                display: flex;

                td {
                    padding: 10px;
                    border: 1px solid rgba(241, 241, 241, 1);
                    flex-grow: 1;
                    flex-basis: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &.label {
                        justify-content: center;
                        background-color: rgba(204, 203, 203, 0.1);
                        font-family: 'Zen Kaku Gothic New', sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 16px;
                        letter-spacing: 0.08em;
                        text-align: center;
                        min-height: 3rem;
                    }

                    &.value {
                        font-family: 'Zen Kaku Gothic New', sans-serif;
                        font-size: 17px;
                        font-weight: 700;
                        line-height: 29px;
                        letter-spacing: 0.08em;
                        text-align: left;
                        padding-left: 3rem;
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .company-overview {
        table {
            width: 100%;

            td {
                display: block;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}