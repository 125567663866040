.translation-service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem;

    .text-content {
        max-width: 55%;
    }

    h2 {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 61px;
        letter-spacing: 0.18em;
        text-align: left;
        width: 80%;

        &::after {
            content: "";
            display: block;
            width: 50px;
            height: 5px;
            background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
            margin: 10px auto 0;
            position: absolute;
        }
    }

    .description {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0.08em;
        text-align: left;
    }

    .service-image {
        max-width: 40%;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    .translation-service {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        padding: 2rem;

        .text-content {
            max-width: unset;

            h2 {
                width: 100%;
            }
        }

        .service-image {
            max-width: 80%;
            height: 200px;
        }
    }
}