.quote-request {
    text-align: center;
    padding: 3rem;
    background-color: rgba(245, 137, 79, 0.07);

    p {
        font-size: 25px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: 0.18em;
        text-align: center;
    }
    .quote-request-button {
        margin-bottom: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
            padding-left: 10px;
        }
    }
}