.intro {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .intro-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 70%;

        h2 {
            font-family: 'Zen Kaku Gothic New', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 44px;
            letter-spacing: 0.18em;
            text-align: center;

            &::after {
                content: "";
                display: block;
                width: 50px;
                height: 5px;
                background: linear-gradient(to right, #F5894F 50%, #FCD4BA 50%);
                margin: 10px auto 0;
            }
        }

        p {
            font-family: 'Zen Kaku Gothic New', sans-serif;
            font-size: 15px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.08em;
            text-align: center;
            margin: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    .intro-content {
        width: 90% !important;
    }
}