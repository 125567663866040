@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zen+Kaku+Gothic+New&display=swap');

html,
body {
    // height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    font-family: 'Noto Sans JP', sans-serif;
    background-color: #FDFCFA;

    h2 {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.22em;
        text-align: center;
    }

    h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0.22em;
        text-align: center;
    }

    p {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0.08em;
        text-align: left;

        &.form-error-message {
            color: red;
            text-align: center;
        }

        &.form-verify-msg {
            color: #00753b;
            text-align: center;
        }
    }

    button {
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 17px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        border-radius: 20px;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        width: 18rem;
        height: 44px;

        &.primary {
            color: #ffffff;
            background-color: rgba(245, 137, 79, 1);

            &:hover {
                background-color: #fd9e7b;
                color: #ffffff;
                border: 2px solid #F5894F;
            }
        }

        &.secondary {
            background-color: #EAEAEA;
            color: #2C2929;

            &:hover {
                background-color: #D0D0D0;
                color: #1F1F1F;
            }
        }
    }

    .swal2-popup {
        border-radius: 10px;
        padding: 2rem;

        .swal2-icon {
            display: none !important;
        }

        .swal2-title {
            font-family: 'Zen Kaku Gothic New', sans-serif;
            font-size: 20px;
            font-weight: bolder;
            line-height: 42px;
            letter-spacing: 0.22em;
            text-align: center;
            color: #000000;
        }

        .swal2-confirm {
            font-family: 'Noto Sans JP', sans-serif;
            font-size: 17px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
            border-radius: 20px;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            width: 18rem;
            height: 44px;
        }
    }

    #scroll-point {
        scroll-margin-top: 6rem;
    }

    .loader-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        /* Semi-transparent background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
}