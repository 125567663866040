.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__calendar-icon {
        width: 1.5em;
        height: 1.5em;
        padding: 0.3rem;
    }
}

.react-datepicker {
    .react-datepicker__navigation-icon--previous {
        width: 100%;

        &::before {
            left: 10px;
        }
    }

    .react-datepicker__navigation-icon--next {
        width: 100%;
        left: 0;

        &::before {
            right: 10px;
            left: unset;
        }
    }
}

.custom-date-input {
    background: #FBFBFB;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 10px 10px 40px !important;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: #333;
}

.custom-date-input:focus {
    outline: none;
    border-color: #e4783e;
    box-shadow: 0 0 5px #e4783e;
}

.custom-file-input {
    background: #F1F1F1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: #333;

    &:focus {
        outline: none;
        border-color: #e4783e;
        box-shadow: 0 0 5px #e4783e;
    }

    .custom-file-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    .file-input {
        display: none;
    }

    .custom-file-button {
        background: #fff;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 5px 10px;
        margin-right: 10px;
        cursor: pointer;
        min-width: 8rem;
    }

    .custom-file-text {
        color: #ccc;
        text-wrap: nowrap;
    }

    .custom-file-label.error {
        border-color: red;
    }
}


.custom-input {
    margin-bottom: 2rem;

    label {
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.008em;
        display: block;
        margin-bottom: 1rem;
    }

    .input-area {
        width: 100%;
    }

    .required {
        color: red;
    }

    .error {
        border-color: red;
    }

    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }

    .custom-text-input {
        background: #F1F1F1;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        font-size: 18px;
        color: #333;

        &:focus {
            outline: none;
            border-color: #e4783e;
            box-shadow: 0 0 5px #e4783e;
        }

        &::placeholder {
            color: #ccc;
        }

        &.error {
            border-color: red;
        }
    }
}

.custom-select-input {
    background: #F1F1F1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="%23333" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 30px 30px;

    &:focus {
        outline: none;
        border-color: #e4783e;
        box-shadow: 0 0 5px #e4783e;
    }

    &.not-selected {
        color: #999;

        option {
            color: #000;
        }

        option[value=""] {
            color: #999;
        }
    }

    .error {
        border-color: red;
    }
}

.custom-textarea-input {
    background: #F1F1F1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: #333;
    resize: vertical;

    &:focus {
        outline: none;
        border-color: #e4783e;
        box-shadow: 0 0 5px #e4783e;
    }

    &.error {
        border-color: red;
    }
}